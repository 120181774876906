import { css } from "goober";

export const SwarmRoster = () => css`
  display: grid;
  gap: var(--sp-12);

  .share {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--sp-3);
    margin-block-start: -1.25rem;

    button {
      cursor: pointer;
    }

    .share-btn:hover,
    .share-btn:focus {
      outline: 2px solid var(--shade0-50);
    }

    .hide-show {
      color: var(--shade2);
      background: transparent;
    }
  }

  .teammates {
    display: grid;
    gap: var(--sp-2);
  }
`;

export const Container = () => css`
  position: relative;
  display: grid;
  gap: var(--sp-3);
  padding: var(--sp-12);

  > * {
    position: relative;
  }

  .splash-bg {
    position: absolute;
    top: -2%;
    right: -38%;
    -webkit-mask-image: radial-gradient(
      54% 63% at center,
      hsl(0deg 0% 0% / 100%),
      transparent 90%
    );
    pointer-events: none;
    width: 120%;
  }

  .wordmark {
    position: absolute;
    width: var(--sp-22);
    height: var(--sp-6);
    top: var(--sp-5);
    right: var(--sp-6);
    mix-blend-mode: overlay;
  }

  .title {
    display: flex;
    align-items: baseline;
    gap: var(--sp-4);
  }
  .outcome {
    font-weight: 725;
    font-size: 3.5rem;
    line-height: 1.25;
    color: var(--outcome-color);

    [data-win="false"] & {
      background: linear-gradient(
        137deg,
        hsl(360deg 88% 55%) 0%,
        hsl(360deg 78% 45%) 100%
      );
      text-shadow: 0 0 8rem hsla(360deg 88% 55% / 0.65);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    [data-win="true"] & {
      background: linear-gradient(
        137deg,
        hsl(178deg 84% 50%) 0%,
        hsl(178deg 100% 30%) 100%
      );
      text-shadow: 0 0 8rem hsla(178deg 84% 50% / 0.65);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .duration {
    color: var(--shade1);
  }

  .middle {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .stats {
    display: grid;
    gap: var(--sp-5);
  }
  .stat {
    position: relative;
    padding-inline-start: var(--sp-9);

    > * {
      position: relative;
    }

    svg {
      position: absolute;
      left: 0;
      top: 50%;
      translate: -30% -50%;
      mix-blend-mode: overlay;
      opacity: 0.75;
    }
    .val,
    .label {
      display: block;
    }
    .val {
      font-weight: 725;
      font-size: 2rem;
      line-height: 1.25;
    }
    .label {
      color: var(--shade1);
    }
  }

  .meta {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: var(--sp-2);
    text-align: right;
    text-shadow: 0 0 20px var(--shade8);

    .champion-name {
      color: var(--shade0-75);
      font-size: var(--sp-6);
      line-height: 1;
    }
    .player-name {
      color: var(--shade0-75);
      font-size: var(--sp-10);
      line-height: 1;
    }
    .items {
      display: flex;
      gap: var(--sp-0_5);
    }
    .item {
      position: relative;
      background: var(--shade9);

      img:not([src]) {
        opacity: 0;
      }

      &::after {
        content: "";
        position: absolute;
        inset: 0;
        border: 1px solid var(--shade0-25);
        box-shadow: 0 0 20px var(--shade8);
      }
    }
  }

  .bars-btns {
    display: flex;
    align-items: center;
    gap: var(--sp-1);

    button {
      color: var(--shade1);
      background: var(--shade1-15);
      border-radius: var(--br-sm);
      cursor: pointer;

      &:hover {
        color: var(--shade1);
        background: var(--shade0-15);
      }
      &.selected {
        color: var(--stat-color);
        background: color-mix(in hsl, var(--stat-color), transparent 85%);
      }
    }
  }
  .bars {
    height: 60px;
    display: flex;
    align-items: baseline;
    gap: 2px;

    &:not(:has(:hover)) .bar:last-child {
      span {
        opacity: 1;
        translate: -50% -100%;
      }
    }
  }
  .bar {
    position: relative;
    height: calc(var(--fill) * 100%);
    background: color-mix(in hsl, var(--stat-color), transparent 75%);
    flex: 1;
    box-shadow: inset 0 2px var(--stat-color);
    transition: background var(--transition);

    span {
      position: absolute;
      top: 0;
      left: 50%;
      translate: -50% -85%;
      color: var(--stat-color);
      opacity: 0;
      transition:
        opacity var(--transition),
        translate var(--transition);
    }

    &:hover {
      background: color-mix(in hsl, var(--stat-color), transparent 50%);

      span {
        opacity: 1;
        translate: -50% -100%;
      }
    }
  }

  hr {
    border: none;
    height: 1px;
    width: 100%;
    background: var(--shade0);
    mix-blend-mode: overlay;
  }

  .outcome-overlay {
    position: absolute;
    top: 0;
    left: 0;
    translate: -4% -43%;
    font-weight: 700;
    font-size: 15rem;
    color: var(--shade0);
    mix-blend-mode: overlay;
    opacity: 0.3;
    pointer-events: none;
  }

  /* secondary players */
  &.is-teammate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--sp-6);
    padding-inline-start: var(--sp-10);

    .splash-bg {
      top: -26%;
      right: -6%;
      width: 55%;
    }

    .stats {
      display: flex;
      gap: var(--sp-10);
    }
    .stat {
      padding-inline-start: var(--sp-6);

      svg {
        width: var(--sp-14);
        height: var(--sp-14);
      }
      .val {
        font-size: 1.25rem;
      }
    }

    .meta {
      gap: 0;

      .champion-name {
        font-size: var(--sp-4);
        line-height: var(--sp-6);
      }
      .player-name {
        font-size: var(--sp-6);
        line-height: var(--sp-9);
      }
    }
  }
`;
